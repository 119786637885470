<script lang="ts">
  import { getContext } from 'svelte'
  import { stripHTML } from 'common/src/utils'
  import { type DeskContent } from 'common/src/types'
  import { state } from '../../../store/state'
  import BaseRenderer from '../cell-renderer.svelte'
  import { cleanRowStatus } from '../cell-renderer'

  import RowCallToAction from '../row-call-to-action/row-call-to-action.svelte'
  import { Presenter } from '../presenter'

  export let contentKindName: string
  export let key: string
  export let value: any
  export let autoWidth: boolean
  export let rowNumber: string
  export let rowStatus: string
  export let selectedId: string
  export let ctaTop: boolean

  export let content: DeskContent

  const { contentKind } = getContext(Presenter.key) as any

  $: contentKindName = state.kindFromType(content?.type).nameOnState

  const getContentTitle = () => {
    const theContent = contentKind?.data.get().find((c: any) => c.id === content?.id)
    if (theContent) {
      return stripHTML(contentKind.kind.getTitle(theContent) || '')
    }

    return ''
  }

  let elem: HTMLElement
  $: {
    cleanRowStatus(elem as HTMLElement, rowStatus)
  }
</script>

{#if key === 'id'}
  <div bind:this={elem}>
    <RowCallToAction {key} {content} {ctaTop} />
  </div>
{:else}
  <BaseRenderer
    {key}
    value={key === 'description' ? getContentTitle() : value}
    {rowNumber}
    {autoWidth}
    {rowStatus}
    {contentKindName}
    {selectedId}
    {content}
  />
{/if}
